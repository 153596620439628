.header {
  height: 10vh;
  background-color: black;
  position: relative;
}

@media (min-width: 992px) {
  .header {
    height: 12vh;
  }
}

.header .logo {
  height: 4vh;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 5vw !important;
}

.header .button-with-outline {
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  color: white;
  position: absolute;
  margin-right: 5vw !important;
  border: 1px solid white;
  font-size: 2.4vw;
  height: 4vh;
}

@media (min-width: 992px) {
  .header .button-with-outline {
    font-size: 0.8vw !important;
  }
}

.two-side-screen {
  height: 85vh;
  background-color: #F9F9F9;
}

.two-side-screen .image-side {
  position: relative;
  height: 40vh;
  width: 100vw;
}

@media (min-width: 992px) {
  .two-side-screen .image-side {
    height: 85vh;
    width: 50vw;
  }
}

.two-side-screen .image-side img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: 25vh;
}

@media (min-width: 576px) {
  .two-side-screen .image-side img {
    height: 30vh;
  }
}

@media (min-width: 992px) {
  .two-side-screen .image-side img {
    height: 45vh;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.two-side-screen .text-side {
  height: 40vh;
}

@media (min-width: 992px) {
  .two-side-screen .text-side {
    height: 85vh;
  }
}

.two-side-screen .text-side h1 {
  margin-top: 12vh;
  padding-left: 0 !important;
  font-size: 15vw;
  color: #F1F1F1;
  margin-left: -0.5vw;
  font-weight: 800;
  margin-bottom: 8vw;
}

@media (min-width: 576px) {
  .two-side-screen .text-side h1 {
    font-size: 12vw;
  }
}

@media (min-width: 992px) {
  .two-side-screen .text-side h1 {
    font-size: 8vw;
  }
}

.two-side-screen .text-side h2 {
  color: black;
  font-size: 6vw;
  margin-left: 8vw;
  margin-right: 8vw;
}

@media (min-width: 576px) {
  .two-side-screen .text-side h2 {
    font-size: 5vw;
  }
}

@media (min-width: 992px) {
  .two-side-screen .text-side h2 {
    font-size: 2.6vw;
  }
}

.two-side-screen .text-side p {
  margin-left: 8vw;
  margin-right: 8vw;
}

.two-side-screen .left h1 {
  margin-left: 8vw !important;
}

.project-card {
  height: 110vh;
  background-color: #F9F9F9;
}

@media (min-width: 992px) {
  .project-card {
    height: 75vh;
  }
}

.project-card .image-side {
  position: relative;
  height: 40vh;
  width: 100vw;
  text-align: center;
}

@media (min-width: 992px) {
  .project-card .image-side {
    height: 75vh;
    width: 50vw;
  }
}

.project-card .image-side img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: 35vh;
}

@media (min-width: 576px) {
  .project-card .image-side img {
    height: 50vh;
  }
}

@media (min-width: 992px) {
  .project-card .image-side img {
    height: 60vh;
  }
}

.project-card .text-side {
  padding-top: 8vh;
  padding-left: 8vw;
  height: 40vh;
}

@media (min-width: 992px) {
  .project-card .text-side {
    padding-top: 18vh;
    padding-left: 8vw;
    height: 75vh;
  }
}

.project-card .text-side .profile-img {
  height: 15vw;
  margin-right: 3vw;
}

@media (min-width: 992px) {
  .project-card .text-side .profile-img {
    height: 6vw;
    margin-right: 3vw;
  }
}

.project-card .text-side .project-name {
  margin-top: 5vw;
  font-size: 5vw;
  color: black;
}

@media (min-width: 992px) {
  .project-card .text-side .project-name {
    margin-top: 1vw;
    font-size: 2.2vw;
  }
}

.project-card .text-side .project-subtitle {
  color: black;
  font-size: 4vw;
  margin-top: 0.7vw;
}

@media (min-width: 992px) {
  .project-card .text-side .project-subtitle {
    font-size: 1.2vw;
  }
}

.project-card .text-side p {
  margin-right: 6vw;
  margin-bottom: 3vw;
  margin-top: 5vw;
  text-align: justify;
}

@media (min-width: 992px) {
  .project-card .text-side p {
    margin-top: 1vw;
  }
}

.project-card .text-side .signature-name {
  font-size: 5vw;
  font-family: Brush Script MT, cursive;
  margin-top: 7vw;
  text-align: center;
}

@media (min-width: 992px) {
  .project-card .text-side .signature-name {
    font-size: 1.5vw;
    margin-top: 0;
    text-align: left;
  }
}

.project-card .text-side .signature-designation {
  font-weight: 500;
  text-align: center;
}

@media (min-width: 992px) {
  .project-card .text-side .signature-designation {
    text-align: left;
  }
}

.project-card .left h1 {
  margin-left: 8vw !important;
}
