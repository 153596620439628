
.header{
    height: 10vh;
    background-color: black;
    position: relative;

    @media (min-width: 992px) {
        height: 12vh;
    }

    .logo {
        height: 4vh;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-left: 5vw !important;
    }

    .button-with-outline {
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        color: white;
        position: absolute;
        margin-right: 5vw !important;
        border: 1px solid white;
        font-size: 2.4vw;
        height: 4vh;

        @media (min-width: 992px) {
            font-size: 0.8vw !important;
        }
    }

}

.two-side-screen{
    height: 85vh;
    background-color: #F9F9F9;

    .image-side{
        position: relative;
        height:40vh;
        width: 100vw;

        @media (min-width: 992px) {
            height: 85vh;
            width: 50vw;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            height: 25vh;

            @media (min-width: 576px) {
                height: 30vh;
            }

            @media (min-width: 992px) {
                    height: 45vh;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: 0;
                    right: 0;
            }
        }        
    }

    .text-side{
        
        height:40vh;

        @media (min-width: 992px) {
            height: 85vh;
        }

        h1{
            margin-top: 12vh;
            padding-left: 0 !important;
            font-size: 15vw;
            color: #F1F1F1;
            margin-left: - 0.5vw;
            font-weight: 800;
            margin-bottom: 8vw;

            @media (min-width: 576px) {
                font-size: 12vw;
            }

            @media (min-width: 992px) {
                font-size: 8vw;
            }
        }

        h2{
            color: black;
            font-size: 6vw;
            margin-left: 8vw;
            margin-right: 8vw;

            @media (min-width: 576px) {
                font-size: 5vw;
            }

            @media (min-width: 992px) {
                font-size: 2.6vw;
            }
        }

        p{

            margin-left: 8vw;
            margin-right: 8vw;
        }
    }

    .left{
        h1{
            margin-left: 8vw !important; 
        }
    }
   
}


.project-card{

    height: 110vh;
    background-color: #F9F9F9;

    @media (min-width: 992px) {
        height: 75vh;
    }


    .image-side{
        position: relative;
        height: 40vh;
        width: 100vw;
        text-align: center;

        @media (min-width: 992px) {

            height: 75vh;
            width: 50vw;

        }


        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            height: 35vh;

            @media (min-width: 576px) {
                height: 50vh;
            }


            @media (min-width: 992px) {
                height: 60vh;
            }
        }
    }

    .text-side{

        padding-top: 8vh;
        padding-left: 8vw;
        height: 40vh;

        @media (min-width: 992px) {
            padding-top: 18vh;
            padding-left: 8vw;
            height: 75vh;
        }

        .profile-img{

            height: 15vw;
            margin-right: 3vw;

            @media (min-width: 992px) {
                height: 6vw;
                margin-right: 3vw;
            }
        }

        .project-name{
            margin-top: 5vw;
            font-size: 5vw;
            color: black;

            @media (min-width: 992px) {
                margin-top: 1vw;
                font-size: 2.2vw;
            }
        }

        .project-subtitle{
            color: black;
            font-size: 4vw;
            margin-top: 0.7vw;

            @media (min-width: 992px) {
                font-size: 1.2vw;
            }

        }

        p{
            margin-right: 6vw;
            margin-bottom: 3vw;
            margin-top: 5vw;
            text-align: justify;

            @media (min-width: 992px) {
                margin-top: 1vw;
                
            }
        }

        .signature-name {
            font-size: 5vw;
            font-family: Brush Script MT, cursive;
            margin-top: 7vw;
            text-align: center;

            @media (min-width: 992px) {
                font-size: 1.5vw;
                margin-top: 0;
                text-align: left;

            }
        }

        .signature-designation {
            font-weight: 500;
            text-align: center;
            @media (min-width: 992px) {
                text-align: left;

            }

        }
    }

    .left{
        h1{
            margin-left: 8vw !important; 
        }
    }
   
}



