.landing-page {
  max-width: 100%;
  overflow-x: hidden;
}

.landing-page .hero-section {
  background-color: black;
  height: 90vh;
}

@media (min-width: 992px) {
  .landing-page .hero-section {
    height: 88vh;
  }
}

.landing-page .hero-section .cta-side {
  color: #f9f9f9;
  padding: 10vw;
  height: 35vh;
  margin-top: -10vh;
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side {
    height: 88vh;
    margin-top: 5vh;
  }
}

.landing-page .hero-section .cta-side .heading1 {
  font-size: 6.5vw;
  font-weight: 600;
  opacity: 0.9;
  text-align: center;
}

@media (min-width: 576px) {
  .landing-page .hero-section .cta-side .heading1 {
    font-size: 5vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .heading1 {
    text-align: left;
    font-size: 3.3vw;
    font-weight: 600;
  }
}

.landing-page .hero-section .cta-side .heading2 {
  font-size: 7.5vw;
  font-weight: 600;
  opacity: 0.9;
  text-align: center;
}

@media (min-width: 576px) {
  .landing-page .hero-section .cta-side .heading2 {
    font-size: 6vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .heading2 {
    text-align: left;
    font-size: 3.3vw;
    font-weight: 600;
  }
}

.landing-page .hero-section .cta-side .para {
  font-size: 3.5vw;
  margin-top: 4vw;
  color: #bdbdbd;
  text-align: center;
}

@media (min-width: 576px) {
  .landing-page .hero-section .cta-side .para {
    font-size: 3vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .para {
    text-align: left;
    font-size: 1.5vw;
  }
}

.landing-page .hero-section .cta-side .cta-section {
  margin-top: 10vw;
  text-align: center;
  justify-items: center;
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .cta-section {
    margin-top: 6.5vw;
  }
}

.landing-page .hero-section .cta-side .cta-section .cta {
  color: #f9f9f9 !important;
  border: 1px solid #bdbdbd !important;
  width: 55vw;
  border-radius: 0.5vw 0vw 0vw 0.5vw;
  height: 15vw;
  padding: 0 5px;
}

@media (min-width: 576px) {
  .landing-page .hero-section .cta-side .cta-section .cta {
    height: 8vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .cta-section .cta {
    width: 25vw;
    height: 4vw;
  }
}

.landing-page .hero-section .cta-side .cta-section .cta:hover {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.landing-page .hero-section .cta-side .cta-section .cta:active {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.landing-page .hero-section .cta-side .cta-section .cta:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline-width: 0 !important;
}

.landing-page .hero-section .cta-side .cta-section .cta ::-webkit-input-placeholder {
  color: #f9f9f9;
  opacity: 0.5;
}

.landing-page .hero-section .cta-side .cta-section .cta :-ms-input-placeholder {
  color: #f9f9f9;
  opacity: 0.5;
}

.landing-page .hero-section .cta-side .cta-section .cta ::-ms-input-placeholder {
  color: #f9f9f9;
  opacity: 0.5;
}

.landing-page .hero-section .cta-side .cta-section .cta ::placeholder {
  color: #f9f9f9;
  opacity: 0.5;
}

.landing-page .hero-section .cta-side .cta-section .cta input {
  color: #bdbdbd;
}

.landing-page .hero-section .cta-side .cta-section .button {
  color: black;
  background-color: #bdbdbd;
  position: absolute;
  font-size: 2.5vw;
  height: 15.5vw;
  border-radius: 0vw 0.5vw 0.5vw 0vw;
  margin-top: 16px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}

@media (min-width: 576px) {
  .landing-page .hero-section .cta-side .cta-section .button {
    height: 8.3vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .cta-side .cta-section .button {
    margin-top: 16px;
    margin-right: 4vw !important;
    height: 4.11vw;
    font-size: 1vw;
  }
}

.landing-page .hero-section .image-side {
  position: relative;
  height: 45vh;
  margin-top: 5vh;
}

@media (min-width: 992px) {
  .landing-page .hero-section .image-side {
    height: 90vh;
  }
}

.landing-page .hero-section .image-side img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: 35vh;
  margin-left: 21vw;
}

@media (min-width: 992px) {
  .landing-page .hero-section .image-side img {
    height: 50vh;
    margin: auto;
  }
}

.landing-page .hero-section .image-side .img-bg {
  margin-left: 0vw;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

@media (min-width: 576px) {
  .landing-page .hero-section .image-side .img-bg {
    margin-left: 16vw;
  }
}

@media (min-width: 992px) {
  .landing-page .hero-section .image-side .img-bg {
    margin-left: 2.3vw;
  }
}

.landing-page .Mui-focused {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.landing-page .contact-section {
  background-color: black;
  height: 100vh;
}

.landing-page .porfolio-section {
  background-color: #f9f9f9;
}

.landing-page .porfolio-section .section-heading {
  text-align: center;
  font-size: 5.5vw;
  font-weight: 600;
}

@media (min-width: 992px) {
  .landing-page .porfolio-section .section-heading {
    font-size: 2.5vw;
  }
}

.landing-page .porfolio-section hr {
  background-color: #fed402;
  margin-left: 54vw;
  margin-right: 40vw;
  height: 5px;
  margin-top: 0.6vw !important;
  margin-bottom: 0 !important;
  border: 0 !important;
}

@media (min-width: 992px) {
  .landing-page .porfolio-section hr {
    font-size: 2.5vw;
  }
}

.landing-page .contact-section {
  background-color: black;
  position: relative;
  text-align: center;
  height: 70vh;
}

@media (min-width: 992px) {
  .landing-page .contact-section {
    height: 100vh;
  }
}

.landing-page .contact-section .section-heading {
  text-align: center;
  font-size: 5.5vw;
  font-weight: 600;
  color: #f9f9f9;
  padding-top: 8vh;
}

@media (min-width: 992px) {
  .landing-page .contact-section .section-heading {
    font-size: 2.5vw;
    padding-top: 20vh;
  }
}

.landing-page .contact-section hr {
  background-color: #fed402;
  margin-left: 54vw;
  margin-right: 40vw;
  height: 5px;
  margin-top: 0.6vw !important;
  margin-bottom: 0 !important;
  border: 0 !important;
}

.landing-page .contact-section .contact-textbox {
  color: #f9f9f9 !important;
  display: absolute;
  margin: auto;
  margin-left: 15vw;
  margin-right: 15vw;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 5vh;
}

@media (min-width: 992px) {
  .landing-page .contact-section .contact-textbox {
    margin-top: 10vh;
    margin-left: 30vw;
    margin-right: 30vw;
  }
}

.landing-page .contact-section .contact-textbox .inputbox {
  margin: 1vh;
  width: 63vw;
  height: 5vh;
  border: 0.5px solid white;
  border-radius: 5px;
  background-color: black;
  color: #f9f9f9;
  padding-left: 1.5vw;
}

@media (min-width: 992px) {
  .landing-page .contact-section .contact-textbox .inputbox {
    width: 45vw;
    height: 5vh;
  }
}

.landing-page .contact-section .contact-textbox .textarea {
  border: 0.5px solid white;
  border-radius: 5px;
  background-color: black;
  color: #f9f9f9;
  padding-left: 1.5vw;
  width: 63vw;
  margin: 1vh;
  height: 20vh;
  padding-top: 1vh;
}

.landing-page .contact-section .contact-textbox .button-with-outline {
  color: black;
  background-color: #f9f9f9;
  border: 1px solid white;
  font-size: 2.5vw;
  height: 4vh;
  margin: auto;
  margin-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

@media (min-width: 992px) {
  .landing-page .contact-section .contact-textbox .button-with-outline {
    font-size: 0.8vw;
  }
}

.landing-page .swiper-button-next {
  color: black;
  font-size: 4vw !important;
  height: 4vw !important;
}

@media (min-width: 992px) {
  .landing-page .swiper-button-next {
    font-size: 2vw !important;
  }
}

.landing-page .swiper-button-prev {
  color: black;
  font-size: 4vw;
}

@media (min-width: 992px) {
  .landing-page .swiper-button-prev {
    font-size: 2vw;
  }
}

.landing-page .swiper-button-next:after {
  color: black;
  font-size: 4vw !important;
}

@media (min-width: 992px) {
  .landing-page .swiper-button-next:after {
    font-size: 2vw !important;
  }
}

.landing-page .swiper-button-prev:after {
  color: black;
  font-size: 4vw !important;
}

@media (min-width: 992px) {
  .landing-page .swiper-button-prev:after {
    font-size: 2vw !important;
  }
}
