$Grey100: #f9f9f9;
$fullHeight: 100vh;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 992px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

.landing-page {
	max-width: 100%;
	overflow-x: hidden;
	.hero-section {
		background-color: black;
		height: 90vh;

		@media (min-width: 992px) {
			height: 88vh;
		}

		.cta-side {
			color: #f9f9f9;
			padding: 10vw;
			height: 35vh;
			margin-top: -10vh;

			@media (min-width: 992px) {
				height: 88vh;
				margin-top: 5vh;
			}

			.heading1 {
				font-size: 6.5vw;
				font-weight: 600;
				opacity: 0.9;
				text-align: center;

				@media (min-width: 576px) {
					font-size: 5vw;
				}

				@media (min-width: 992px) {
					text-align: left;
					font-size: 3.3vw;
					font-weight: 600;
				}
			}

			.heading2 {
				font-size: 7.5vw;
				font-weight: 600;
				opacity: 0.9;
				text-align: center;

				@media (min-width: 576px) {
					font-size: 6vw;
				}

				@media (min-width: 992px) {
					text-align: left;
					font-size: 3.3vw;
					font-weight: 600;
				}
			}

			.para {
				font-size: 3.5vw;
				margin-top: 4vw;
				color: #bdbdbd;
				text-align: center;

				@media (min-width: 576px) {
					font-size: 3vw;
				}

				@media (min-width: 992px) {
					text-align: left;
					font-size: 1.5vw;
				}
			}

			.cta-section {
				margin-top: 10vw;
				text-align: center;
				justify-items: center;

				@media (min-width: 992px) {
					margin-top: 6.5vw;
				}

				.cta {
					color: #f9f9f9 !important;
					border: 1px solid #bdbdbd !important;
					width: 55vw;
					border-radius: 0.5vw 0vw 0vw 0.5vw;
					height: 15vw;
					padding: 0 5px;

					@media (min-width: 576px) {
						height: 8vw;
					}

					@media (min-width: 992px) {
						width: 25vw;
						height: 4vw;
					}
				}

				.cta:hover {
					outline: none !important;
					box-shadow: none !important;
				}

				.cta:active {
					outline: none !important;
					box-shadow: none !important;
				}

				.cta:focus {
					outline: none !important;
					box-shadow: none !important;
					outline-width: 0 !important;
				}

				.cta ::placeholder {
					color: #f9f9f9;
					opacity: 0.5;
				}

				.cta input {
					color: #bdbdbd;
				}

				.button {
					color: black;
					background-color: #bdbdbd;
					position: absolute;
					font-size: 2.5vw;
					height: 15.5vw;
					border-radius: 0vw 0.5vw 0.5vw 0vw;
					margin-top: 16px;
					padding-left: 1.5vw;
					padding-right: 1.5vw;

					@media (min-width: 576px) {
						height: 8.3vw;
					}

					@media (min-width: 992px) {
						margin-top: 16px;
						margin-right: 4vw !important;
						height: 4.11vw;
						font-size: 1vw;
					}
				}
			}
		}

		.image-side {
			position: relative;
			height: 45vh;
			margin-top: 5vh;

			@media (min-width: 992px) {
				height: 90vh;
			}

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 0;
				right: 0;
				height: 35vh;
				margin-left: 21vw;

				@media (min-width: 992px) {
					height: 50vh;
					margin: auto;
				}
			}

			.img-bg {
				margin-left: 0vw;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				left: 0;
				right: 0;

				@media (min-width: 576px) {
					margin-left: 16vw;
				}

				@media (min-width: 992px) {
					margin-left: 2.3vw;
				}
			}
		}
	}

	.Mui-focused {
		outline: none !important;
		border: none !important;
		box-shadow: none !important;
	}

	.contact-section {
		background-color: black;
		height: $fullHeight;
	}

	.porfolio-section {
		background-color: #f9f9f9;

		.section-heading {
			text-align: center;
			font-size: 5.5vw;
			font-weight: 600;

			@media (min-width: 992px) {
				font-size: 2.5vw;
			}
		}

		hr {
			background-color: #fed402;
			margin-left: 54vw;
			margin-right: 40vw;
			height: 5px;
			margin-top: 0.6vw !important;
			margin-bottom: 0 !important;
			border: 0 !important;

			@media (min-width: 992px) {
				font-size: 2.5vw;
			}
		}
	}

	.contact-section {
		background-color: black;
		position: relative;
		text-align: center;
		height: 70vh;

		@media (min-width: 992px) {
			height: 100vh;
		}

		.section-heading {
			text-align: center;
			font-size: 5.5vw;
			font-weight: 600;
			color: #f9f9f9;
			padding-top: 8vh;

			@media (min-width: 992px) {
				font-size: 2.5vw;
				padding-top: 20vh;
			}
		}

		hr {
			background-color: #fed402;
			margin-left: 54vw;
			margin-right: 40vw;
			height: 5px;
			margin-top: 0.6vw !important;
			margin-bottom: 0 !important;
			border: 0 !important;
		}

		.contact-textbox {
			color: #f9f9f9 !important;
			display: absolute;
			margin: auto;
			margin-left: 15vw;
			margin-right: 15vw;
			justify-content: center;
			margin-top: 5vh;

			@media (min-width: 992px) {
				margin-top: 10vh;
				margin-left: 30vw;
				margin-right: 30vw;
			}

			.inputbox {
				margin: 1vh;
				width: 63vw;
				height: 5vh;
				border: 0.5px solid white;
				border-radius: 5px;
				background-color: black;
				color: #f9f9f9;
				padding-left: 1.5vw;

				@media (min-width: 992px) {
					width: 45vw;
					height: 5vh;
				}
			}

			.textarea {
				border: 0.5px solid white;
				border-radius: 5px;
				background-color: black;
				color: #f9f9f9;
				padding-left: 1.5vw;
				width: 63vw;
				margin: 1vh;
				height: 20vh;
				padding-top: 1vh;
			}

			.button-with-outline {
				color: black;
				background-color: #f9f9f9;
				border: 1px solid white;
				font-size: 2.5vw;
				height: 4vh;
				margin: auto;
				margin-top: 2vw;
				padding-left: 2vw;
				padding-right: 2vw;

				@media (min-width: 992px) {
					font-size: 0.8vw;
				}
			}
		}
	}

	.swiper-button-next {
		color: black;
		font-size: 4vw !important;
		height: 4vw !important;

		@media (min-width: 992px) {
			font-size: 2vw !important;
		}
	}

	.swiper-button-prev {
		color: black;
		font-size: 4vw;

		@media (min-width: 992px) {
			font-size: 2vw;
		}
	}

	.swiper-button-next:after {
		color: black;
		font-size: 4vw !important;

		@media (min-width: 992px) {
			font-size: 2vw !important;
		}
	}

	.swiper-button-prev:after {
		color: black;
		font-size: 4vw !important;

		@media (min-width: 992px) {
			font-size: 2vw !important;
		}
	}
}
